import './index.scss';

import CONSTANTS from '../../../../Constants';
import useCustomNavigation from '../../../../Hooks/use-custom-navigation';
import WrapperSection from '../../../../Components/WrapperSection';
import Button from '../../../../Components/Button';
import { useTranslation } from 'react-i18next';
import { useRulesOfThrees } from '../../../../Hooks/use-rules-of-threes';
import UseServices from '../../../../Hooks/use-services';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AnnoucementDto } from '../../../../Services/Annoucement';
import { motion } from 'framer-motion';
import { CloseIcon } from '../../../../Icons/close-icon';
import { theme } from '../../../../Style/theme';
import Copydeck from '../../../../i18n/Copydeck';
import AnnoucementTitle from '../../../../Layout/Components/annoucement-title';
import { ArrowRightIcon } from '../../../../Icons/arrow-right-icon';
import useAnnoucementLearnMore from '../../../../Hooks/use-annoucement-learn-more';

interface HeroProps {
    onPressAnnoucement?: (id: string) => void;
}

const Hero: React.FC<HeroProps> = ({ onPressAnnoucement }) => {
    const nav = useCustomNavigation();
    const { t } = useTranslation();
    const { annoucementService } = UseServices();
    const [lastAnnoucement, setLastAnnoucement] = useState<AnnoucementDto | null>(null);
    const [lastAnnoucementId, setLastAnnoucementId] = useState<string | null>(null);
    const [showLastAnnoucement, setShowLastAnnoucement] = useState(false);
    const onClickLearnMore = useAnnoucementLearnMore();

    const height = useRulesOfThrees(1100);

    const onClickProductOverview = () => nav(CONSTANTS.SCREEN.WHAT_IS_SPREEAI);

    const getLastAnnoucement = async () => {
        try {
            const annoucement = await annoucementService.getLastOne();
            if (!annoucement) {
                return;
            }

            const isClosed = await annoucementService.isClosedAnnoucement(annoucement.id);
            if (isClosed) {
                return;
            }

            setLastAnnoucement(annoucement?.data);
            setShowLastAnnoucement(true);
            setLastAnnoucementId(annoucement.id);
        } catch (error) {
            console.error(error);
            toast.error(t('generic_api_error') as string);
        }
    };

    const onCloseAnnoucement = async () => {
        if (!lastAnnoucementId) {
            return;
        }

        try {
            await annoucementService.closeAnnoucement(lastAnnoucementId);
            setShowLastAnnoucement(false);
        } catch (error) {
            console.error(error);
            toast.error(t('generic_api_error') as string);
        }
    };

    useEffect(() => {
        getLastAnnoucement();
    }, []);

    const onClickGoToAnnoucement = () => {
        if (!lastAnnoucement || !lastAnnoucementId || !onPressAnnoucement) {
            return;
        }

        onPressAnnoucement(lastAnnoucementId);
    };

    return (
        <WrapperSection className="Hero">
            <div
                className="wrapper-video"
                style={{
                    height,
                }}
            >
                <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    style={{
                        height,
                    }}
                >
                    <source
                        src={require('../../../../Assets/MP4/view-demo-complete.webm')}
                        type="video/webm"
                    />
                    <source
                        src={require('../../../../Assets/MP4/view-demo-complete.mp4')}
                        type="video/mp4"
                    />
                </video>
            </div>

            {showLastAnnoucement && lastAnnoucement ? (
                <motion.div
                    className="annoucement-toast"
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                >
                    <div className="box">
                        <div className="left">
                            <div className="whatnews text-body-primary-font-bold text-white">
                                {Copydeck.annoucementSectionWhatsNews}
                            </div>

                            <div className="content">
                                <div className="annoucement-title text-body text-white">
                                    <AnnoucementTitle annoucement={lastAnnoucement}>
                                        <span>
                                            {lastAnnoucement?.redirectionUrl && (
                                                <div
                                                    onClick={() =>
                                                        onClickLearnMore(
                                                            lastAnnoucement?.redirectionUrl
                                                        )
                                                    }
                                                    className="text-body-bold text-white learn-more"
                                                >
                                                    {
                                                        Copydeck.annoucementSectionLearnMoreAnnoucement
                                                    }
                                                </div>
                                            )}

                                            <button
                                                onClick={onClickGoToAnnoucement}
                                                className={`wrapper-arrow-right btn-delete-default-style ${
                                                    lastAnnoucement?.redirectionUrl
                                                        ? '-has-link'
                                                        : ''
                                                }`}
                                            >
                                                <ArrowRightIcon
                                                    height={24}
                                                    width={24}
                                                    fill={theme['white']}
                                                    onClick={onClickGoToAnnoucement}
                                                />
                                            </button>
                                        </span>
                                    </AnnoucementTitle>
                                </div>
                            </div>
                        </div>

                        <div className="right">
                            <CloseIcon fill={theme['white']} onClick={onCloseAnnoucement} />
                        </div>
                    </div>
                </motion.div>
            ) : null}

            <div className="inner">
                <div className="extra-info">
                    <WrapperSection.Title htmlTag="h1" className="text-white">
                        {t('HomePage.home_page_title')}
                    </WrapperSection.Title>

                    <div className="text-body text-white">{t('HomePage.home_page_subtitle')}</div>
                </div>

                <Button
                    type="white"
                    text={t('HomePage.cta_title_primary')}
                    onClick={onClickProductOverview}
                />
            </div>
        </WrapperSection>
    );
};

export default Hero;
