import './index.scss';
import WrapperSection from '../../../../Components/WrapperSection';
import UseServices from '../../../../Hooks/use-services';
import { useCallback, useEffect, useState } from 'react';
import { ArticleDto } from '../../../../Services/Blog';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../../../../Constants';
import useCustomNavigation from '../../../../Hooks/use-custom-navigation';
import { useRulesOfThrees } from '../../../../Hooks/use-rules-of-threes';
import ClockIcon from '../../../../Icons/ClockIcon';
import Button from '../../../../Components/Button';
import { theme } from '../../../../Style/theme';
import { calculateReadingTime, formatDate } from '../../../../Utils/object';
import Copydeck from '../../../../i18n/Copydeck';
import { SupportedLanguage } from '../../../../Stores/LanguageStore';
import { useStores } from '../../../../Hooks/use-stores';

const BlogPageBanner = () => {
    const { blogService } = UseServices();
    const { languageStore } = useStores();
    const [lastArticle, setLastArticle] = useState<ArticleDto | null>(null);
    const { t } = useTranslation();
    const nav = useCustomNavigation();
    const height = useRulesOfThrees(1100);
    const [readingTime, setReadingTime] = useState<number>(0);

    useEffect(() => {
        if (!lastArticle) {
            return;
        }

        const blocks = lastArticle.attributes.blocks;
        let numberOfCharacters = 0;

        blocks.forEach((block) => {
            if (block.__component === 'shared.rich-text') {
                numberOfCharacters += block.body.length;
            }
        });

        setReadingTime(calculateReadingTime(numberOfCharacters));
    }, [lastArticle?.id]);

    const getTheLastestArticles = useCallback(async () => {
        try {
            const response = await blogService.getMostRecentArticles(1);

            if (!response?.data?.[0]) {
                toast.error(t('BlogPage.fetch_error') as string);
            }
            setLastArticle(response?.data?.[0]);
        } catch (error) {
            console.error(error);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getTheLastestArticles();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClickArticle = () => {
        if (lastArticle) {
            nav(CONSTANTS.SCREEN.BLOG_ID, `${lastArticle.id}-${lastArticle.attributes.slug}`);
        }
    };

    return (
        <div className="BlogPageBanner">
            <div className="banner-title">
                <span className="text-body-medium-primary-font">
                    SpreeAI<span className="title-green">Blog</span>
                </span>
            </div>

            <div
                className="wrapper-video"
                style={{
                    height,
                }}
            >
                <img
                    className="img"
                    src={lastArticle?.attributes?.cover?.data?.attributes?.formats?.medium?.url}
                    alt={lastArticle?.attributes?.title}
                />
            </div>

            <div className="overlay" />

            <div className="wrapper-content-info">
                <div className="content-info">
                    {lastArticle?.attributes?.category?.data?.attributes.name && (
                        <div className="cateogry">
                            <div className="text-white text-body-medium">
                                {lastArticle?.attributes?.category?.data?.attributes.name || 'none'}
                            </div>
                        </div>
                    )}

                    <WrapperSection.Title className="text-white">
                        {lastArticle?.attributes?.title}
                    </WrapperSection.Title>

                    {(lastArticle?.attributes?.authors?.data?.length || 0) > 0 && (
                        <div className="text-white text-body">
                            {Copydeck.blogPageBannerBy}:{' '}
                            {lastArticle?.attributes?.authors?.data
                                ?.map((author) => author.attributes.name)
                                .join(', ')}
                        </div>
                    )}
                    {lastArticle?.attributes?.publishedAt && (
                        <p className="text-white text-body">
                            {formatDate(
                                lastArticle?.attributes?.publishedAt || '',
                                languageStore.currentLanguage || SupportedLanguage.EN
                            )}
                        </p>
                    )}
                    <div className="text-white wrapper-time text-body">
                        <ClockIcon fill={theme['white']} />
                        <span className="text-body text-white">
                            {readingTime} {t('min')}
                        </span>
                    </div>

                    <div>
                        <Button
                            fullInMobile={false}
                            text="View Blog Post"
                            type="white"
                            onClick={onClickArticle}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogPageBanner;
