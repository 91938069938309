import React, { useMemo } from 'react';
import { AnnoucementDto } from '../../../Services/Annoucement';
import { useStores } from '../../../Hooks/use-stores';
import { SupportedLanguage } from '../../../Stores/LanguageStore';

interface AnnoucementTitleProps {
    annoucement: AnnoucementDto;
    children?: React.ReactNode;
}

const AnnoucementTitle: React.FC<AnnoucementTitleProps> = ({ annoucement, children }) => {
    const { languageStore } = useStores();

    const title = useMemo(() => {
        switch (languageStore.currentLanguage) {
            case SupportedLanguage.EN:
                return annoucement.Title;
            case SupportedLanguage.FR:
                return annoucement.TitleFR;
            case SupportedLanguage.IT:
                return annoucement.TitleIT;
            case SupportedLanguage.ES:
                return annoucement.TitleES;
            default:
                return annoucement.Title;
        }
    }, [annoucement, languageStore]);

    return (
        <div className="announcement-title text-body-primary-font text-white">
            {title ? title : annoucement.Title}

            {children ? children : null}
        </div>
    );
};

export default AnnoucementTitle;
