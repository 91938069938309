import { useStores } from './use-stores';

const useAnnoucementLearnMore = () => {
    const { onboardingModalStore } = useStores();

    const onClick = (redirectionUrl: string) => {
        if (redirectionUrl.includes('youtube')) {
            onboardingModalStore.setIsVisible(true, redirectionUrl);
            return;
        }

        if (redirectionUrl.includes('#')) {
            window.location.href = redirectionUrl;
            return;
        }

        window.open(redirectionUrl, '_blank')?.focus();
    };

    return onClick;
};

export default useAnnoucementLearnMore;
